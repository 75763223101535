import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserAuthService } from "../shared/services/user-auth.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  email: string;
  isSendingRequest: boolean;
  invalidCredentials: boolean;
  invalidEmail = false;
  loginState = "login"; // login, reset, sent, done
  hide = true;

  constructor(
    private router: Router,
    private UserAuthService: UserAuthService,
    private CookieService: CookieService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.username = "";
    this.password = "";
    this.loginState = this.route.snapshot.paramMap.get("state")
      ? this.route.snapshot.paramMap.get("state")
      : "login";
  }

  doReset(): void {
    this.invalidEmail = false;
    this.isSendingRequest = true;

    this.UserAuthService.forgotPassword({ email: this.email }).subscribe(
      (data: any) => {
        this.isSendingRequest = false;
        if (data.results) {
          this.email = "";
          this.invalidEmail = false;
          this.loginState = "sent";
        } else {
          this.invalidEmail = false;
          this.loginState = "sent";
        }
      },
      (err) => {
        this.isSendingRequest = false;
        this.invalidEmail = false;
        this.loginState = "sent";
      }
    );
  }

  doLogin(): void {
    // this.router.navigateByUrl("/admin-portal");
    this.isSendingRequest = true;
    this.UserAuthService.isUserAuthenticated(
      this.username,
      this.password
    ).subscribe((data: any) => {
      if (data.results.message) this.invalidCredentials = true;
      else {
        const user = {
          id: data.results.check_user_id,
          username: data.results.check_user_username,
          email: data.results.check_user_email,
          userType: data.results.check_user_userType,
          clientID: data.results.check_user_clientID,
        };
        console.log(user.clientID);
        this.CookieService.set("user", JSON.stringify(user));
        if (data.results.check_user_userType == 0) {
          this.router.navigateByUrl("/admin-portal");
        } else {
          // this.router.navigateByUrl("/main");
          this.router.navigateByUrl("/user-portal");
        }
      }
      this.isSendingRequest = false;
    });
  }
}
