export class Tracking {

  surveyBaseURL: string;
  surveyParams: string;
  pageTracking: boolean;
  projectID: string;
  cookieDuration: number;

  constructor() {
    this.surveyBaseURL = "";
    this.surveyParams = "";
    this.pageTracking = false;
    this.projectID = "";
    this.cookieDuration = 30;
  }

}