import { Injectable } from "@angular/core";
import { Project } from "../models/project.model";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class ProjectService2 {

  constructor(private http: HttpClient) {}

  get(clientID: string) {
    return this.http.get(
      `${environment.apiURL}/v1/project/?clientID=${clientID}`
    );
  }

  post(project: Project) {
    project.projectOptions.isSPA = (project.projectOptions.isSPA + '' === 'true');
    const body = {
      name: project.name,
      projectOptions: JSON.stringify(project.projectOptions),
      isActive: project.isActive ? 1 : 0,
      isDeleted: project.isDeleted ? 1 : 0,
      clientID: project.clientID,
    };

    return this.http.post(`${environment.apiURL}/v1/project/add`, body);
  }

  put(project: Project) {
    project.projectOptions.isSPA = (project.projectOptions.isSPA + '' === 'true');
    const body = {
      id: project.id,
      name: project.name,
      projectOptions: JSON.stringify(project.projectOptions),
      isActive: project.isActive ? 1 : 0,
      isDeleted: project.isDeleted ? 1 : 0,
      clientID: project.clientID,
    };

    return this.http.put(`${environment.apiURL}/v1/project/update`, body);
  }
}
