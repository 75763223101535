<!-- <div class="card shadow" style="width: 20rem;">
  <div
    class="card-header d-flex justify-content-center mb-3 border"
    style="background-color:whitesmoke"
  >
    <img src="assets/img/concentrix_research_ops.png" width="100%" height="100%" />
  </div>
  <div class="card-body">
    <input
      #loginusername
      type="text"
      class="form-control mb-4"
      placeholder="Username"
    />

    <input
      #loginpassword
      type="password"
      class="form-control mb-4"
      placeholder="Password"
    />

    <button
      color="info"
      block="true"
      class="btn btn-primary"
      (click)="doLogin()"
      type="submit"
    >
      Sign in
    </button>

    <div class="invalid-feedback">
      Invalid Username Or Password
    </div>
  </div>
</div> -->

<mat-card
  class="mat-elevation-z8"
  style="
    width: 400px;
    height: 450px;
    margin: auto;
    margin-top: 8em;
    border-radius: 10px;
  "
>
  <mat-card-content class="uk-text-center">
    <img
      mat-card-image
      src="assets/img/concentrix_research_ops.png"
      style="width: 200px; margin: 0 auto"
    />
    <hr />

    <!-- login -->
    <div *ngIf="loginState == 'login'">
      <mat-form-field
        appearance="outline"
        class="uk-width-1-1"
        style="padding: 0 32px"
      >
        <mat-label>Username</mat-label>
        <input
          matInput
          [(ngModel)]="username"
          (ngModelChange)="invalidCredentials = false"
        />
        <mat-icon matSuffix color="primary" style="margin-right: 4px"
          >account_box</mat-icon
        >
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="uk-width-1-1"
        style="padding: 0 32px"
      >
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          [(ngModel)]="password"
          (ngModelChange)="invalidCredentials = false"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
      </mat-form-field>
      <div
        class="uk-width-1-1 uk-margin-small-bottom"
        style="padding: 0 32px"
        *ngIf="invalidCredentials"
      >
        <span class="uk-text-small uk-text-danger">
          Invalid username or password.
        </span>
      </div>
      <div class="uk-width-1-1" style="padding: 0 32px">
        <button
          mat-flat-button
          color="accent"
          class="uk-width-1-1"
          (click)="doLogin()"
          [disabled]="isSendingRequest"
        >
          LOGIN
        </button>
      </div>

      <button
        mat-button
        style="padding: 5px 20px"
        *ngIf="!isSendingRequest"
        (click)="loginState = 'reset'"
      >
        <mat-icon matSuffix color="primary">lock_open</mat-icon>
        Forgot Password
      </button>

      <div
        class="uk-width-1-1 uk-margin-top"
        style="padding: 0 32px"
        *ngIf="isSendingRequest"
      >
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>

    <!-- forget password -->
    <div *ngIf="loginState == 'reset'">
      <div
        (click)="loginState = 'login'; invalidEmail = false; email = ''"
        style="text-align: right; cursor: pointer"
      >
        <mat-icon matSuffix color="primary">close</mat-icon>
      </div>
      <mat-form-field
        appearance="outline"
        class="uk-width-1-1"
        style="padding: 18px 32px 0px"
      >
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="email" />
        <mat-icon matSuffix color="primary">email</mat-icon>
      </mat-form-field>
      <div class="uk-width-1-1 uk-margin-small-bottom" *ngIf="invalidEmail">
        <span class="uk-text-small uk-text-danger">
          Account does not exist or Invalid Email
        </span>
      </div>
      <div class="uk-width-1-1" style="padding: 0px 32px">
        <button
          mat-flat-button
          color="accent"
          class="uk-width-1-1"
          (click)="doReset()"
          [disabled]="isSendingRequest"
        >
          RESET
        </button>
      </div>

      <div
        class="uk-width-1-1 uk-margin-top"
        style="padding: 0 32px"
        *ngIf="isSendingRequest"
      >
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>

    <!-- email sent -->
    <div *ngIf="loginState == 'sent'">
      <div
        (click)="loginState = 'login'; invalidEmail = false"
        style="text-align: right; cursor: pointer"
      >
        <mat-icon matSuffix color="primary">close</mat-icon>
      </div>
      <div class="uk-width-1-1" style="padding: 40px 32px">
        Check your email for the new password. Click the x in the upper-right
        corner to login.
      </div>
    </div>
  </mat-card-content>
</mat-card>
