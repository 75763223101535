<!-- INITIAL INVITE -->
<div
  [hidden]="currentModal != 1"
  class="modal-dialog modal-dialog-centered"
  role="document"
>
  <div
    class="modal-content"
    [style.width.px]="project.settings.popupModal.width"
    [style.height.px]="project.settings.popupModal.height"
    [style.borderRadius.px]="project.settings.invite.borderRadius"
    [style.boxShadow]="selectedPage.inviteContent.modalBSStyle"
  >
    <div class="modal-body">
      <div class="row">
        <button
          mat-icon-button
          style="margin-left: auto"
          (click)="closePopup()"
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center autoheight">
          <div
            #elInviteContent
            class="autoheight"
            style="width: 100%; max-width: 900px; word-wrap: break-word"
          ></div>
        </div>
      </div>
      <br />
      <div class="row">
        <div
          class="col"
          [ngStyle]="selectedPage.inviteContent.btnContainerStyle"
        >
          <button
            type="button"
            id="primaryBtnIC"
            class="btn"
            [ngStyle]="selectedPage.inviteContent.primaryBtnStyle"
            [style.backgroundColor]="
              selectedPage.inviteContent.primaryBtnBGColor
            "
            [style.color]="selectedPage.inviteContent.primaryBtnColor"
            [style.fontFamily]="selectedPage.inviteContent.primaryBtnFontStyle"
            [style.fontSize.pt]="selectedPage.inviteContent.primaryBtnFontSize"
            [style.width]="
              selectedPage.inviteContent.primaryBtnWidth == 'auto'
                ? 'auto'
                : selectedPage.inviteContent.primaryBtnWidth + 'px'
            "
            [style.height]="
              selectedPage.inviteContent.primaryBtnHeight == 'auto'
                ? 'auto'
                : selectedPage.inviteContent.primaryBtnHeight + 'px'
            "
            style="margin-right: 8px; word-break: break-all"
            (click)="acceptInitialInvite()"
          >
            {{ selectedPage.inviteContent.primaryBtnText }}
          </button>
          <button
            *ngIf="selectedPage.acceptAction != 'delayedSurvey'"
            type="button"
            id="secondaryBtnIC"
            class="btn"
            style="word-break: break-all"
            [ngStyle]="selectedPage.inviteContent.secondaryBtnStyle"
            [style.backgroundColor]="
              selectedPage.inviteContent.secondaryBtnBGColor
            "
            [style.color]="selectedPage.inviteContent.secondaryBtnColor"
            [style.fontFamily]="
              selectedPage.inviteContent.secondaryBtnFontStyle
            "
            [style.fontSize.pt]="
              selectedPage.inviteContent.secondaryBtnFontSize
            "
            [style.width]="
              selectedPage.inviteContent.secondaryBtnWidth == 'auto'
                ? 'auto'
                : selectedPage.inviteContent.secondaryBtnWidth + 'px'
            "
            [style.height]="
              selectedPage.inviteContent.secondaryBtnHeight == 'auto'
                ? 'auto'
                : selectedPage.inviteContent.secondaryBtnHeight + 'px'
            "
          >
            {{ selectedPage.inviteContent.secondaryBtnText }}
          </button>
          <button
            *ngIf="selectedPage.acceptAction == 'delayedSurvey'"
            type="button"
            id="secondaryBtnIC"
            class="btn"
            style="word-break: break-all"
            [ngStyle]="selectedPage.inviteContent.secondaryBtnStyle"
            [style.backgroundColor]="
              selectedPage.inviteContent.secondaryBtnBGColor
            "
            [style.color]="selectedPage.inviteContent.secondaryBtnColor"
            [style.fontFamily]="
              selectedPage.inviteContent.secondaryBtnFontStyle
            "
            [style.fontSize.pt]="
              selectedPage.inviteContent.secondaryBtnFontSize
            "
            [style.width]="
              selectedPage.inviteContent.secondaryBtnWidth == 'auto'
                ? 'auto'
                : selectedPage.inviteContent.secondaryBtnWidth + 'px'
            "
            [style.height]="
              selectedPage.inviteContent.secondaryBtnHeight == 'auto'
                ? 'auto'
                : selectedPage.inviteContent.secondaryBtnHeight + 'px'
            "
            (click)="acceptInitialInvite()"
          >
            {{ selectedPage.inviteContent.secondaryBtnText }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center">
          <p style="font-style: italic; margin-top: 36px; font-size: 12px">
            Review the
            <a href="https://survey3.sendyouropinions.com/images/privacy.htm"
              >Privacy Statement</a
            >
            for this survey.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- SEND INVITE SELECTION -->
<div
  [hidden]="currentModal != 2"
  class="modal-dialog modal-dialog-centered"
  role="document"
>
  <div
    class="modal-content"
    [style.width.px]="selectedPage.surveySelection.modalWidth"
    [style.height.px]="selectedPage.surveySelection.modalHeight"
    [style.borderRadius.px]="selectedPage.surveySelection.modalBorderRadius"
    [style.boxShadow]="selectedPage.surveySelection.modalBSStyle"
  >
    <div class="modal-body">
      <div class="row">
        <button
          mat-icon-button
          style="margin-left: auto"
          (click)="closePopup()"
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <br />
      <div class="row">
        <div class="col d-flex justify-content-center">
          <div
            #elSendSelectContent
            style="width: 100%; max-width: 900px; word-wrap: break-word"
          ></div>
        </div>
      </div>
      <br />
      <div class="row">
        <div
          class="col"
          [ngStyle]="selectedPage.surveySelection.selectionBtnContainerStyle"
        >
          <button
            type="button"
            class="btn"
            [ngStyle]="selectedPage.surveySelection.primaryBtnStyle"
            [style.backgroundColor]="
              selectedPage.surveySelection.primaryBtnBGColor
            "
            [style.color]="selectedPage.surveySelection.primaryBtnColor"
            [style.fontFamily]="
              selectedPage.surveySelection.primaryBtnFontStyle
            "
            [style.fontSize.pt]="
              selectedPage.surveySelection.primaryBtnFontSize
            "
            [style.width]="
              selectedPage.surveySelection.primaryBtnWidth == 'auto'
                ? 'auto'
                : selectedPage.surveySelection.primaryBtnWidth + 'px'
            "
            [style.height]="
              selectedPage.surveySelection.primaryBtnHeight == 'auto'
                ? 'auto'
                : selectedPage.surveySelection.primaryBtnHeight + 'px'
            "
            (click)="onSelectPhone()"
          >
            {{ selectedPage.surveySelection.primaryBtnText }}
          </button>
          <button
            type="button"
            class="btn"
            [ngStyle]="selectedPage.surveySelection.secondaryBtnStyle"
            [style.backgroundColor]="
              selectedPage.surveySelection.secondaryBtnBGColor
            "
            [style.color]="selectedPage.surveySelection.secondaryBtnColor"
            [style.fontFamily]="
              selectedPage.surveySelection.secondaryBtnFontStyle
            "
            [style.fontSize.pt]="
              selectedPage.surveySelection.secondaryBtnFontSize
            "
            [style.width]="
              selectedPage.surveySelection.secondaryBtnWidth == 'auto'
                ? 'auto'
                : selectedPage.surveySelection.secondaryBtnWidth + 'px'
            "
            [style.height]="
              selectedPage.surveySelection.secondaryBtnHeight == 'auto'
                ? 'auto'
                : selectedPage.surveySelection.secondaryBtnHeight + 'px'
            "
            (click)="onSelectEmail()"
          >
            {{ selectedPage.surveySelection.secondaryBtnText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- SEND INVITE INPUT -->
<div
  [hidden]="currentModal != 3"
  class="modal-dialog modal-dialog-centered"
  role="document"
>
  <div
    class="modal-content"
    [style.width.px]="selectedPage.surveyContent.modalWidth"
    [style.height.px]="selectedPage.surveyContent.modalHeight"
    [style.borderRadius.px]="selectedPage.surveyContent.modalBorderRadius"
    [style.boxShadow]="selectedPage.surveyContent.modalBSStyle"
  >
    <div class="modal-body">
      <div class="row">
        <button
          mat-icon-button
          style="margin-left: auto"
          (click)="closePopup()"
        >
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <br />
      <div class="row">
        <div
          [ngClass]="inputType == 'email' ? 'show-el' : 'hide-el'"
          class="col d-flex justify-content-center"
        >
          <div
            #elSendInputEmailContent
            style="width: 100%; max-width: 900px; word-wrap: break-word"
          ></div>
        </div>
        <div
          [ngClass]="inputType == 'phone' ? 'show-el' : 'hide-el'"
          class="col d-flex justify-content-center"
        >
          <div
            #elSendInputPhoneContent
            style="width: 100%; max-width: 900px; word-wrap: break-word"
          ></div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col d-flex justify-content-center">
          <label for="contact" style="padding-right: 10px">{{
            inputType == "phone"
              ? selectedPage.surveySelection.phoneText
              : selectedPage.surveySelection.emailText
          }}</label>
          <input type="text" style="width: 60%" />
        </div>
      </div>
      <br />
      <br />
      <div class="row">
        <div
          class="col"
          [ngStyle]="selectedPage.surveyContent.btnContainerStyle"
        >
          <button
            type="button"
            class="btn"
            [ngStyle]="selectedPage.surveyContent.primaryBtnStyle"
            [style.backgroundColor]="
              selectedPage.surveyContent.primaryBtnBGColor
            "
            [style.color]="selectedPage.surveyContent.primaryBtnColor"
            [style.fontFamily]="selectedPage.surveyContent.primaryBtnFontStyle"
            [style.fontSize.pt]="selectedPage.surveyContent.primaryBtnFontSize"
            [style.width]="
              selectedPage.surveyContent.primaryBtnWidth == 'auto'
                ? 'auto'
                : selectedPage.surveyContent.primaryBtnWidth + 'px'
            "
            [style.height]="
              selectedPage.surveyContent.primaryBtnHeight == 'auto'
                ? 'auto'
                : selectedPage.surveyContent.primaryBtnHeight + 'px'
            "
          >
            {{ selectedPage.surveyContent.primaryBtnText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- DELAYED SURVEY -->
